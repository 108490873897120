<template>
  <!--
    The view for the Installation Converter Routing Rules
  -->
  <div class="installationDetail_ConverterRoutingRules">
    <template>
      <div class="card">
        <Portlet
          :title="$t('routingRulesForms.routingRules')"
          icon="route"
        >
          <template slot="buttons">
            <button
              class="btn btn-sm btn-primary float-right"
              @click="showSidebarAdd"
            >
              <font-awesome-icon
                class="mr-1"
                icon="plus"
              />
              <span>{{ $t('routingRulesForms.addRoutingRules') }}</span>
            </button>
          </template>
          <LoadingPlaceholder v-if="loading" />
          <template v-else>
            <RoutingRulesList
              ref="routingRulesList"
              :resort-id="installationObject.resortId"
              @reloadAuditLogs="reloadAuditLogs"
            />
            <DatabaseAuditLogList
              ref="databaseAuditLogList"
              :is-routing-rules="true"
              :parent-resort-id="installationObject.resortId"
              @reload="reloadRoutingRules"
            />
          </template>
        </Portlet>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "InstallationDetailConverterRoutingRules",
  components: {
    RoutingRulesList: () => import('@/components/Resort/RoutingRulesList.vue'),
    DatabaseAuditLogList: () =>
      import("@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue"),
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      loadingErrors: true,
      installationObject: null
    };
  },
  metaInfo () {
    return {
      title: 'Section'
    };
  },
  created () {
    this.getInstallation();
  },
  methods: {
    reloadRoutingRules () {
      this.$refs.routingRulesList.reloadRoutingRules();
    },
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    showSidebarAdd () {
      this.$refs.routingRulesList.showSidebarAdd();
    },
    getInstallation () {
      this.loading = true;
      this.axios
        .get("/Installation/Get?id=" + this.installationId)
        .then(response => {
          this.installationObject = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>